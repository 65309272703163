<template>
  <!-- 号码池页面 -->
  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>号码列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button
              icon="el-icon-search"
              class="button-el"
              size="medium"
              @click="theQueryDialog = true"
              >查询
            </el-button>
          </div>

          <div class="ml-10">
            <el-button
              class="button-el"
              size="medium"
              @click="batchDelete"
              >批量删除
            </el-button>
          </div>
          <div class="ml-10">
            <el-button
            
              class="button-el"
              size="medium"
              @click="Add"
              >增加
            </el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div
                class="font-refresh-out flex-nowrap-center-center"
                @click="getNumberList()"
              >
                <icon-font
                  v-if="loading == false"
                  type="icon-shuaxin"
                  class="font-refresh"
                />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            max-height="700"
            size="mini"
            :row-style="{ height: '50px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="用户ID" fixed width="130px">
              <template #default="scope"
                >{{ scope.row.uid == null ? "-" : scope.row.uid }}
              </template>
            </el-table-column>
            <el-table-column label="坐席手机号码">
              <template #default="scope"
                >{{ scope.row.seatPhone == null ? "-" : scope.row.seatPhone }}
              </template>
            </el-table-column>
            <el-table-column label="行业编码">
              <template #default="scope"
                >{{
                  scope.row.businessCode == null ? "-" : scope.row.businessCode
                }}
              </template>
            </el-table-column>
            <el-table-column label="坐席编码">
              <template #default="scope"
                >{{ scope.row.seatCode == null ? "-" : scope.row.seatCode }}
              </template>
            </el-table-column>
            <el-table-column label="用户姓名">
              <template #default="scope"
                >{{ scope.row.nickname == null ? "-" : scope.row.nickname }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="操作" fixed="right" width="180">
              <template #default="scope">
              
                <div class="flex-nowrap-space-around">
                  <span class="button-font-color" @click="bindFun(scope.row)">
                    <icon-font type="icon-bangding"/>
                    绑定
                  </span>
                  <span class="button-font-color" @click="modify(scope.row)">
                    <icon-font type="icon-bianji"/>
                    编辑
                  </span>
                  <span class="button-font-color" @click="confirmEvent(scope.row.id)">
                    <icon-font type="icon-shanchu"/>
                    删除
                  </span>
                </div>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[20, 30, 40, 50, 100, 500]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 弹窗→操作号码 -->
    <div class="popup-view">
      <el-form
        label-position="left"
        label-width="100px"
        :model="upform"
        ref="upform1"
        :rules="rules"
      >
        <el-dialog
          :title="operationType == 1 ? '添加数据' : '修改数据'"
          v-model="centerDialogVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="30%"
        >
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <div class="form-out-box p-0-30">
              <el-form-item label="号码：" prop="number">
                <el-input
                  v-if="operationType == 1"
                  v-model="upform.number"
                  :autosize="{ minRows: 3, maxRows: 4 }"
                  type="textarea"
                  placeholder="请输入号码(输入为多个号码时请换行输入)"
                ></el-input>
                <el-input
                  v-else
                  v-model.trim="upform.number"
                  placeholder="请输入号码"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="错误号码：" class="redItem" v-if="showError == true">
                      <div class="redItem">{{ errorNumber }}</div>
                    </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码设置：">
                <el-input
                  v-model="upform.options"
                  type="textarea"
                  placeholder="请填写号码设置"
                  :autosize="{ minRows: 2, maxRows: 6 }"
                />
              </el-form-item>
              <!-- <el-form-item label="地区" prop="areaCode">
                <el-cascader :options="options" :props="{value: 'name',label: 'name'}" v-model="upformArea" @change="upformAreaChange"></el-cascader>
              </el-form-item> -->
              <!-- <el-form-item label="省份：">
                <el-input v-model="upform.province" placeholder="请输入省份"></el-input>
              </el-form-item> -->
              <el-form-item label="省份：" prop="value3">
                <el-cascader
                  filterable
                  clearable
                  placeholder="选择省份城市"
                  style="width: 100%"
                  v-model="upform.value3"
                  :options="option"
                ></el-cascader>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <!-- <el-form-item label="城市：">
                <el-input v-model="upform.city" placeholder="请输入城市"></el-input>
              </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="区号：" prop="areaCode">
                <el-input
                  v-model="upform.areaCode"
                  placeholder="请输入区号"
                ></el-input>
              </el-form-item>
              <!-- </el-col> -->
              <!-- <el-col :span="8">-->
              <el-form-item label="服务商：" prop="amountId">
                <el-select
                  v-model="upform.amountId"
                  placeholder="请选择"
                  filterable
                  style="width: 100%"
                  clearable
                  @change="changeValues"
                >
                  <el-option
                    v-for="(item, index) in amount_id"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>  -->
              <!-- <el-col :span="8"> -->
              <el-form-item label="接口类型：" prop="interfaceId">
                <el-select
                  v-model="upform.interfaceId"
                  placeholder="请选择"
                  filterable
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in interface_id"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码状态：" prop="status">
                <el-select
                  v-model="upform.status"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in ['正常', '禁用']"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="计费类型：" prop="type">
                <el-select
                  v-model="upform.type"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in ['单计费', '双计费']"
                    :key="index"
                    :label="item"
                    :value="index + 1"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="号码行业：" prop="industryId">
                <el-cascader
                  :props="industryTreeProps"
                  filterable
                  placeholder="选择/搜索号码行业"
                  clearable
                  style="width: 100%"
                  v-model="industryValue"
                  :options="filterIndustryList"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="禁入行业：" prop="unindustryIds">
                <el-cascader
                  :props="unIndustryTreeProps"
                  filterable
                  placeholder="选择/搜索禁入行业"
                  clearable
                  style="width: 100%"
                  v-model="unIndustryValue"
                  :options="filterIndustryList"
                ></el-cascader>
              </el-form-item>

              <!-- <el-select v-model="upform.businessType" placeholder="请选择" style="width: 100%" clearable>
                <el-option v-for="(item, index) in industryList" :key="index" :label="item.value" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
              <!-- </el-col>
                  <el-col :span="8"> -->
              <el-form-item label="是否录音：" prop="record">
                <el-select
                  v-model="upform.record"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in ['否', '是']"
                    :key="index"
                    :label="item"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerDialogVisible = false"
                round
                >取消</el-button
              >
              <el-button
                class="confirmAdd"
                @click="addSubmit(operationType, 'upform1')"
                round
              >
                保存
              </el-button>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→导入文件 -->
    <div class="popup-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="upform"
        ref="upform"
      >
        <el-dialog
          title="号码导入"
          v-model="centerUploadVisible"
          width="50%"
          :close-on-click-modal="false"
          :destroy-on-close="true"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="8">
                <el-form-item label="是否录音：">
                  <el-select
                    v-model="importArray.record"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['否', '是']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="号码行业：">
                  <el-cascader
                    :props="industryTreeProps"
                    filterable
                    placeholder="请选择"
                    clearable
                    style="width: 100%"
                    v-model="importArray.businessType"
                    :options="industryTreeList"
                  ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="接口类型：">
                  <el-select
                    v-model="importArray.interfaceId"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in interface_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerUploadVisible = false"
                round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="importFileFun()" round>
                提交
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→号码绑定 -->
    <div class="popup-view">
      <el-form
        label-position="left"
        label-width="90px"
        :model="bind_form"
        :rules="rules"
        ref="Bind_form"
        size="mini"
      >
        <el-dialog
          title="号码绑定"
          v-model="centerBindVisible"
          width="390px"
          :close-on-click-modal="false"
          :destroy-on-close="true"
        >
          <div class="center-body p-0-20">
            <!-- <el-row :gutter="12">
              <el-col :span="8"> -->
            <el-form-item label="绑定号码：">
              <el-input v-model="bind_form.telX" disabled></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="主叫号码：" prop="telA">
              <el-input
                v-model="bind_form.telA"
                placeholder="请输入主叫号码"
              ></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="被叫号码：" prop="telB">
              <el-input
                v-model="bind_form.telB"
                placeholder="请输入被叫号码"
              ></el-input>
            </el-form-item>
            <!-- </el-col>
              <el-col :span="8"> -->
            <el-form-item label="绑定时间：" prop="expire">
              <el-input-number
                v-model="bind_form.expire"
                @change="handleChange"
                :min="3"
                :max="30"
                label="设置绑定时间"
              >
              </el-input-number>
            </el-form-item>
            <!-- </el-col>
            </el-row> -->
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="cancelBindNumber('Bind_form')"
                round
                >取消</el-button
              >
              <el-button
                class="confirmAdd"
                @click="bindNumber('Bind_form')"
                round
                >绑定</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→回收小号 -->
    <div class="pop-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="bind_form"
        ref="bind_form"
      >
        <el-dialog
          :title="recyclingType == 1 ? '回收小号' : '退网小号'"
          :before-close="bfClose"
          v-model="centerBranchVisibleOther"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="30%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col
                :span="recyclingType == 1 ? 8 : 24"
                :push="recyclingType == 1 ? 2 : 0"
              >
                <!-- <el-form-item
                  :label="'小号：' + multipleSelection.length + '个'"
                >
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item> -->
                <el-input
                  type="textarea"
                  placeholder="请输入小号，每行一个小号"
                  v-model="inputMobiles"
                  :rows="12"
                ></el-input>
                <div
                  v-if="recyclingType == 2"
                  style="
                    font-size: 14px;
                    color: #aaa;
                    margin-top: 5px;
                    margin-left: 5px;
                  "
                >
                  需要退网的号码必须完成回收操作，正在使用的号码无法强行退网
                </div>
                <div
                  style="
                    font-size: 14px;
                    color: #aaa;
                    margin-top: 5px;
                    margin-left: 5px;
                  "
                  class="noticetext"
                  v-html="recycleNumbers"
                ></div>
                <div
                  style="
                    font-size: 14px;
                    color: #aaa;
                    margin-top: 5px;
                    margin-left: 5px;
                  "
                  class="noticetext"
                  v-html="existNumbers"
                ></div>
              </el-col>
              <el-col :span="1" v-if="recyclingType == 1" :push="2">
                <el-button size="small" plain @click="search"
                  >搜索<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </el-col>
              <el-col :span="10" v-if="recyclingType == 1" :push="5">
                <!-- <el-form-item label="用户：">
                  <el-select v-model="uids" filterable multiple placeholder="请选择用户">
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="item.nickname"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                  >全选
                </el-checkbox>
                <div style="margin: 15px 0"></div>
                <el-checkbox-group
                  class="checkbox"
                  v-model="checkedPerson"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    v-for="item in persons"
                    :label="item.uid"
                    :key="item.uid"
                  >
                    {{ item.nickname }}--{{ item.company }}--(ID:{{ item.uid }})
                  </el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="bfClose(false)" round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="backNum()" round
                >{{ recyclingType == 1 ? "回收" : "退网" }}
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→分配小号 -->

    <div class="pop-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="bind_form"
        ref="bind_form"
        :rules="rules"
      >
        <el-dialog
          title="分配小号"
          v-model="centerBranchVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          @close="cancelBranchNumber()"
          width="30%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="10">
                <el-form-item
                  :label="'小号：' + multipleSelection.length + '个'"
                >
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item label="用户：" prop="uid">
                  <el-select
                    @change="ReloadBranchNumberError"
                    v-model="branch_form.uid"
                    filterable
                    placeholder="请选择"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="`${item.nickname || ''}--${
                        item.company || ''
                      }--(Id:${item.uid})`"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1 ? 'no-red' : ''">
                          <span>{{ item.nickname }}--</span>
                          <span>{{ item.company }}--</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
                <div>
                  <template
                    v-if="
                      errObj.errList &&
                      errObj.errList.length !== 0 &&
                      errObj.errCode === 203
                    "
                  >
                    <div>以下线路未设置价格：</div>
                    <div class="showErrList">
                      <div v-for="(item, i) in errObj.errList" :key="i">
                        <span class="no-red">{{ item.interfaceName }}</span>
                        <span class="ml-10 no-red">{{
                          typeText(item.type)
                        }}</span>
                      </div>
                    </div>
                  </template>
                  <template v-if="errObj.errCode === 204">
                    <div class="showErrList">
                      <div>
                        分配失败！失败原因：<span class="no-red"
                          ><br />
                          {{ errObj.errText }}</span
                        >
                      </div>
                    </div>
                  </template>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item :label="'备注'">
                  <el-input
                    v-model="branch_form.remark"
                    :rows="2"
                    type="textarea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="cancelBranchNumber()"
                round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="branchNumber()" round
                >分配
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 弹窗→操作号码 -->

    <div class="popup-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="allEideFrom"
        ref="upformChange"
        :rules="rules"
      >
        <el-dialog
          title="批量编辑"
          v-model="allEidtDialog"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          width="50%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item
                  :label="'小号：' + multipleSelection.length + '个'"
                >
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="号码状态：" prop="status">
                  <el-select
                    v-model="allEideFrom.status"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['正常', '禁用']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="计费类型：" prop="type">
                  <el-select
                    v-model="allEideFrom.type"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['单计费', '双计费']"
                      :key="index"
                      :label="item"
                      :value="index + 1"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="号码行业：" prop="industryId">
                  <el-cascader
                    :props="industryTreeProps"
                    filterable
                    placeholder="请选择"
                    clearable
                    style="width: 100%"
                    v-model="allEideFrom.industryId"
                    :options="industryTreeList"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="是否录音：" prop="record">
                  <el-select
                    v-model="allEideFrom.record"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['否', '是']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="服务商">
                  <el-select
                    v-model="allEideFrom.amountId"
                    placeholder="请选择查询的服务商"
                    style="width: 100%"
                    clearable
                    filterable
                    @change="changeValues"
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="接口类型">
                  <el-select
                    v-model="allEideFrom.interfaceId"
                    placeholder="请选择查询的接口类型"
                    style="width: 100%"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(item, index) in interface_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="allEidtDialog = false"
                round
                >取消</el-button
              >
              <el-button
                class="confirmAdd"
                @click="allEidtSubmit('upformChange')"
                round
              >
                保存
              </el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
    <!-- 批量添加 -->
    <div>
      <el-dialog
        :title="importType == 1 ? '导入并分配' : '文件导入'"
        v-model="showBulkImport"
        width="30%"
        :close-on-click-modal="false"
        :destroy-on-close="true"
      >
        <bulkImport
          @confirmOK="confirmImports"
          @quxiaos="quxiaosData"
          :importType="importType"
          :industry-list="industryList"
          :industry-tree-list="industryTreeList"
        ></bulkImport>
      </el-dialog>
    </div>
    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <div class="">
          <el-form
            label-position="left"
            label-width="100px"
            size="small"
            :model="searchForm"
            ref="upform"
          >
            <div class="form-out-box p-0-30">
              <el-form-item label="坐席号码">
                <el-input
                  v-model.trim="searchForm.seatPhone"
                  placeholder="请输入坐席号码"
                  clearable
                ></el-input>
              </el-form-item>

              <el-form-item label="行业code">
                <el-input
                  v-model.trim="searchForm.businessCode"
                  placeholder="请输入查询的次数"
                  clearable
                ></el-input>
              </el-form-item>

              <el-form-item label="分配用户">
                <el-select
                  v-model="searchForm.uid"
                  placeholder="请选择"
                  style="width: 100%"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="(item, index) in user_list"
                    :key="index"
                    :label="`${item.nickname || ''}--${
                      item.company || ''
                    }--(Id:${item.uid})`"
                    :value="item.uid"
                  >
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1 ? 'no-red' : ''">
                        <span>{{ item.nickname }}--</span>
                        <span>{{ item.company }}--</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间筛选">
                <el-date-picker
                  v-model="searchDateTime"
                  :style="{ width: '80%' }"
                  type="datetimerange"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :default-time="[
                    new Date(2000, 1, 1, 0, 0, 0),
                    new Date(2000, 2, 1, 23, 59, 59),
                  ]"
                  range-separator="-"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                />
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import amount from "@/api/open/amount/amount";

import number from "@/api/open/privacy/number";
import interfacets from "@/api/open/privacy/interface";
import amountts from "@/api/open/privacy/amount";
import callts from "@/api/open/privacy/call";
import userts from "@/api/web/user";
import usernumber from "@/api/open/privacy/userNumber";
import { message } from "ant-design-vue";
import ImportFile from "@/components/importFile.vue";
import bulkImport from "@/components/bulkImport1.vue";
import { IconFont } from "@/utils/iconfont";
import dictionary from "@/api/system/dictionary";
import area from "@/api/open/area/area";
import { toRaw } from "vue";
import deepClone from "@/utils/deepClone";

export default {
  name: "Number",
  components: {
    ImportFile,
    bulkImport,
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem;
      if (value == null) {
        this.showError = false;
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else if (value.length == 0) {
        this.showError = false;
        callback(new Error("请填写号码 (多个号码请换行输入)"));
      } else {
        let newArr = [];
        newArr = value.split(/[(\r\n)\r\n]+/);
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$|^9\d{9}$|^2\d{9}$|^8\d{9}$/
            ) == null
            // item.match(
            //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
            // ) == null
          ) {
            // if(item.match(/(010\d{8})|(0[2-9]\d{9})|(13\d{9})|(14[57]\d{8})|(15[0-35-9]\d{8})|(18[0-35-9]\d{8})/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++;
            types = i;
            errorItem = item;
          }
        });
        if (num != 0) {
          this.showError = true;
          this.errorNumber = errorItem;
          callback(
            new Error(
              "请输入正确的号码 (请检查第" +
                (types + 1) +
                "行：" +
                errorItem +
                ")"
            )
          );
        } else {
          this.showError = false;
          callback();
        }
      }
    };
    return {
      allIds: [],
      total: 0,
      tableData: [],
      searchDateTime: [],
      theQueryDialog: false, //弹窗--查询
      user_list: [], // 用户的集合
      searchForm: {
        page: 1,
        pageSize: 20,
        //搜索项
        seatPhone: "", //坐席号码
        businessCode: "", //行业code
        uid: "", //用户id
        createTimeBegin: "",
        createTimeEnd: "",
      },
      loading: false,
      rules: {},
    };
  },
  watch: {
    //深度监听this.searchDateTime
    searchDateTime: {
      handler: function (val, oldVal) {
        if (val) {
          this.searchForm.createTimeBegin = val[0];
          this.searchForm.createTimeEnd = val[1];
        } else {
          this.searchForm.createTimeBegin = "";
          this.searchForm.createTimeEnd = "";
        }
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.getNumberList();
    this.getUserList();
  },
  computed: {},
  methods: {
    batchDelete(){

      // if(this.allIds.length === 0){
      //   return this.$message.warning('请选择要删除的号码')
      // }
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        amount.amountDeleteHunan({ids:this.allIds}).then(res=>{
          
       
            this.$message.success('删除成功')
            this.getNumberList()
     
          
          
        
        })
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    Add() {
      this.$router.push({
        path: "/settings/AddHunanBack",
        query: { id: "" },
      });
    },

    //根据号码搜索用户
    search() {
      let arr = this.inputMobiles.split(/\r\n|\r|\n/);
      arr = [...new Set(arr)];
      this.inputMobiles = arr.join("\n");
      if (!this.inputMobiles) {
        return this.$notify.warning("请输入小号");
      }
      number.numberUser(arr).then((res) => {
        if (res.code !== 200) {
          this.persons = [];
          this.$message.error(res.message);
        } else {
          this.persons = res.data;
        }
      });
    },
    handleCheckAllChange(val) {
      this.checkedPerson = val ? this.persons.map((e) => e.uid) : [];
      // console.log(val)
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.persons.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.persons.length;
    },

    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = []
      val.forEach((item) => {
        newIds.push(item.id)
      })
     // this.multipleSelection = val
      this.allIds = newIds
    },

    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data;
      });
    },

    cancelBindNumber(formName) {
      this.$refs[formName].resetFields();
      this.centerBindVisible = false;
    },

    // 重置搜索项
    searchTapReset(kind) {
      this.searchForm.seatPhone = "";
      this.searchDateTime = [];
      this.searchForm.uid = "";
      this.searchForm.businessCode = "";
    },
    // 搜索特殊项
    searchTapFun() {
      this.theQueryDialog = false;



      this.getNumberList()

    },

    // 获取电话池列表
    getNumberList() {
      this.loading = true;
      amount
        .amountListHunan({
          ...this.searchForm,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.loading = false;
          this.total = res.data.total;
        });
    },

    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.getNumberList();
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val;
      this.currentPage = val;
      this.getNumberList();
    },
  },
};
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}

.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}

.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}

.confirmAdd {
  background: #637dff;
  color: #fff;
}

.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}

.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}

.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}

.redItem {
  color: #f0142f;
}

.showErrList {
  height: 200px;
  overflow: scroll;
  width: 250px;
}

::v-deep(.checkbox .el-checkbox) {
  display: block;
}

.noticetext span {
  color: #f0142f;
}
</style>
